<template>
    <section class="content">
        <div class="card card-primary">
            <div class="card-header">
                <h3 class="card-title">Permission {{ $route.params.id }}</h3>
            </div>
        </div>
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Action</th>
                    <th>Options</th>
                    <th>Status</th>
                    <th>Code</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <input type="hidden" name="row_id" value="" />
                                    <label class="control-label">Module Code</label>
                                    <input class='form-control' v-model="form.module_code" type='text' required='required' name='module_code'/>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Module Name</label>
                                    <input type="text" name="module_name" placeholder="" v-model="form.module_name" class="form-control" />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="control-label">Roles</label>
                                    <v-select :options="relesopt" :reduce="opt => opt.code" :value="form.module_roles" v-model="form.module_roles" multiple></v-select>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
export default {
    name: 'Permit',
    data() {
        return {
            errors: [],
            relesopt: [
                {label: 'Read', code: 'read'}, 
                {label: 'create', code: 'create'},
                {label: 'update', code: 'update'},
                {label: 'delete', code: 'delete'}
            ],
            method: '',
            form: {
                module_code: '',
                module_name: '',
                module_roles:[]
            },
            ids:0,
            formTitle: 'Add permission'
        }
    },
    components: {
        vSelect
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        this.ids = this.$route.params.id;
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('input#read')) {
                this.suffleOption(
                    e.target.getAttribute('value'),
                    e.target.getAttribute('id'),
                    e.target.checked ? 1 : 0
                    );
            }
            if (e.target.matches('input#create')) {
                this.suffleOption(
                    e.target.getAttribute('value'),
                    e.target.getAttribute('id'),
                    e.target.checked ? 1 : 0
                    );
            }
            if (e.target.matches('input#update')) {
                this.suffleOption(
                    e.target.getAttribute('value'),
                    e.target.getAttribute('id'),
                    e.target.checked ? 1 : 0
                    );
            }
            if (e.target.matches('input#delete')) {
                this.suffleOption(
                    e.target.getAttribute('value'),
                    e.target.getAttribute('id'),
                    e.target.checked ? 1 : 0
                    );
            }
            if (e.target.matches('button.checkall') || e.target.closest('button.checkall')) {
                this.suffleOptionGroup(
                    e.target.getAttribute('value') ? e.target.getAttribute('value') : e.target.closest('button.checkall').getAttribute('value'),
                    e.target.getAttribute('class') ? e.target.getAttribute('class') : e.target.closest('button.checkall').getAttribute('class'),
                    e.target.checked ? 1 : 0
                    );
            }
            if (e.target.matches('button.clearall') || e.target.closest('button.clearall')) {
                this.suffleOptionGroup(
                    e.target.getAttribute('value') ? e.target.getAttribute('value') : e.target.closest('button.clearall').getAttribute('value'),
                    e.target.getAttribute('class') ? e.target.getAttribute('class') : e.target.closest('button.clearall').getAttribute('class'),
                    e.target.checked ? 1 : 0
                    );
            }
            if (e.target.matches('button.inverse') || e.target.closest('button.inverse')) {
                this.suffleOptionGroup(
                    e.target.getAttribute('value') ? e.target.getAttribute('value') : e.target.closest('button.inverse').getAttribute('value'),
                    e.target.getAttribute('class') ? e.target.getAttribute('class') : e.target.closest('button.inverse').getAttribute('class'),
                    1
                    );
            }
        },
        suffleOption(id,key,action) {
            var urlSubmit = '/users/role_group/setup_access';
            var datas = {
                        action: action,
                        key: key,
                        i_group: this.ids,
                        module_id: id
                    };
            var data = Object.keys(datas).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(datas[key])).join('&')

            authFetch(urlSubmit, {
                    method: 'POST',
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload(null, false);
                });
        },
        suffleOptionGroup(id,key,action) {
            var urlSubmit = '/users/role_group/setup_access_group';
            var datas = {
                        action: action,
                        key: key,
                        i_group: this.ids,
                        module_id: id
                    };
            var data = Object.keys(datas).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(datas[key])).join('&')

            authFetch(urlSubmit, {
                    method: 'POST',
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload(null, false);
                });
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/users/role_group';
            if (this.method == 'PUT') urlSubmit = '/users/role_group/' + this.form.mid;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        }
    },
    mounted() {
        console.log('load initial data', this.$route.params.roles);
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "Data User Group",
            "roles": this.$route.params.roles,
            "ajax": "/users/role_group/"+this.ids,
            "columns": [
                { "data": "mid" },
                { "data": "mname" },
                { "data": "mact" },
                { "data": "mopt" },
                { "data": "msts" },
                { "data": "module_code", visible: false },
            ],
            filterBy: [1,5],
            "rowCallback": function(row, data) {
            },
            buttonClick: (evt) => {
                console.log(evt);
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Permission';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    var role = [];
                    if (evt.data.module_roles.length > 0) {
                        role = evt.data.module_roles.split(',');
                        evt.data.module_roles = role;
                    }
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Permission';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/users/role_group/' + evt.data.mid, {
                            method: 'DELETE'
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload(null, false);
                        });
                }

            },
        });
    }
}
</script>